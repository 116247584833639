<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <CCol cols="8">
          <h1 class="mr-sm-4 header">UPLOAD DOCUMENT</h1>
        </CCol>
        <CCol cols="4" class="text-right">
          <b-dropdown
            id="dropdown-1"
            class="mr-2 btn-mains small-dropdown medium-dd"
            right
            no-flip
          >
            <template v-slot:button-content>
              <font-awesome-icon
                icon="ellipsis-v"
                title="filter-btn"
                class="text-white d-sm-none"
              />
              <span class="d-none d-sm-inline">ACTION</span>
            </template>
            <b-dropdown-item @click="downloadData"
              >Download Data</b-dropdown-item
            >
            <b-dropdown-item @click="exportData(1)"
              >Export Excel</b-dropdown-item
            >
            <b-dropdown-item @click="exportData(2)">Export CSV</b-dropdown-item>
          </b-dropdown>
          <b-button v-b-toggle.sidebar-1 class="btn-filter">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="text-white mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline">FILTER</span>
          </b-button>
        </CCol>
      </CRow>
      <b-sidebar
        id="sidebar-1"
        title="FILTER"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
      >
        <div class="px-3 py-2">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-link px-0"
              @click="onClearFilter()"
            >
              Clear
            </button>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <p class="font-weight-bold my-2">Start Date</p>
              <datetime
                placeholder="Please select date"
                class="date-filter"
                v-model="filter.startDate"
                format="dd MMM yyyy"
              ></datetime>
            </div>
            <div class="col-6">
              <p class="font-weight-bold my-2">End Date</p>
              <datetime
                placeholder="Please select date"
                class="date-filter"
                v-model="filter.endDate"
                format="dd MMM yyyy"
              ></datetime>
            </div>
          </div>

          <p class="text-danger text-center my-3" v-if="error">
            Please enter correct date
          </p>

          <div>
            <p class="font-weight-bold my-2">Status</p>
          </div>

          <div class="row">
            <div class="col-6" v-for="status in statusList" :key="status.id">
              <div class="form-check mb-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="status.id"
                  v-model="filter.documentStatus"
                  checked="checked"
                  :id="'status-' + status.id"
                />
                <label class="form-check-label" :for="'status-' + status.id">{{
                  status.name
                }}</label>
              </div>
            </div>
          </div>

          <div class="text-center mt-5">
            <button
              type="button"
              class="btn btn-main button"
              @click="getDataByStatus()"
            >
              Submit
            </button>
          </div>
        </div>
      </b-sidebar>
      <div class="bg-white-border px-4 px-sm-5 mt-3 py-4">
        <b-row class="no-gutters justify-content-between">
          <b-col md="5" class="px-0 py-3 pt-lg-2">
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="Customer Name, ID Card, Line Name"
                v-model="filter.search"
                @keyup="handleSearch"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              responsive
              class="text-center"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(ids)="data">
                <b-form-checkbox
                  size="lg"
                  class="ml-3"
                  :value="data.item.id"
                  v-model="selected"
                ></b-form-checkbox>
              </template>
              <template v-slot:head(ids)="data">
                <b-form-checkbox
                  size="lg"
                  class="ml-3"
                  :ref="data.field.key"
                  v-model="selectedAll"
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(createdTime)="data">
                <span>{{
                  new Date(data.item.createdTime) | moment("DD MMM YYYY HH:mm")
                }}</span>
              </template>
              <template v-slot:cell(customerName)="data">
                <div
                  v-if="
                    data.item.customerName == null ||
                    data.item.customerName == ''
                  "
                >
                  - -
                </div>
                <div v-else>{{ data.item.customerName }}</div>
              </template>
              <template v-slot:cell(lineName)="data">
                <div
                  v-if="data.item.lineName == null || data.item.lineName == ''"
                >
                  -
                </div>
                <div v-else>{{ data.item.lineName }}</div>
              </template>
              <template v-slot:cell(idCard)="data">
                <div v-if="data.item.idCard == null || data.item.idCard == ''">
                  -
                </div>
                <div v-else>{{ data.item.idCard }}</div>
              </template>
              <template v-slot:cell(attachFile)="data">
                <div v-if="data.item.attachFile == true">
                  ใช่
                  <span v-if="data.item.removeFiles > 0">
                    (ลบแล้ว {{ data.item.removeFiles }} ไฟล์)</span
                  >
                </div>
                <div v-else>ไม่ใช่</div>
              </template>
              <template v-slot:cell(files)="data">
                <div class="d-flex justify-content-center">
                  <div
                    v-for="(item, index) in data.item.files.slice(0, 2)"
                    :key="index"
                  >
                    <div
                      v-if="
                        item.url.includes('jpg') ||
                        item.url.includes('png') ||
                        item.url.includes('jpeg')
                      "
                      v-bind:style="{ 'background-image': `url(${item.url})` }"
                      class="img-doc mx-1 m-unset pointer b-contain"
                      @click="showPreview(item.url)"
                    ></div>
                    <div
                      v-else-if="item.url.includes('pdf')"
                      v-bind:style="{
                        'background-image':
                          `url(` +
                          require('@/assets/images/icons/pdf.png') +
                          `)`,
                      }"
                      class="img-doc mr-2 m-unset icon-file icon-center"
                      :title="item.url"
                    ></div>
                    <div
                      v-else-if="item.url.includes('tiff')"
                      v-bind:style="{
                        'background-image':
                          `url(` +
                          require('@/assets/images/icons/tiff.svg') +
                          `)`,
                      }"
                      class="img-doc mr-2 m-unset icon-file icon-center"
                      :title="item.url"
                    ></div>
                    <div
                      v-else-if="item.url.includes('heic')"
                      v-bind:style="{
                        'background-image':
                          `url(` +
                          require('@/assets/images/icons/heic.png') +
                          `)`,
                      }"
                      class="img-doc mr-2 m-unset icon-file icon-center"
                      :title="item.url"
                    ></div>
                    <div
                      v-else-if="item.url.includes('heif')"
                      v-bind:style="{
                        'background-image':
                          `url(` +
                          require('@/assets/images/icons/heif.png') +
                          `)`,
                      }"
                      class="img-doc mr-2 m-unset icon-file icon-center"
                      :title="item.url"
                    ></div>
                    <div
                      v-else-if="item.url.includes('nef')"
                      v-bind:style="{
                        'background-image':
                          `url(` +
                          require('@/assets/images/icons/nef.png') +
                          `)`,
                      }"
                      class="img-doc mr-2 m-unset icon-file icon-center"
                      :title="item.url"
                    ></div>
                  </div>
                  <router-link
                    :to="'/document/details/' + data.item.id"
                    class="text-dark"
                  >
                    <div
                      class="img-doc see-all-pic"
                      v-if="data.item.files.length > 3"
                    >
                      <span>ดูทั้งหมด ({{ data.item.files.length - 2 }})</span>
                    </div>
                  </router-link>
                </div>
              </template>
              <template v-slot:cell(documentStatusName)="data">
                <span class="doc-status" :style="{ color: data.item.statusColor }">{{
                  data.item.documentStatusName
                }}</span>
              </template>
              <template v-slot:cell(id)="data">
                <b-button variant="link" class="px-1 py-0">
                  <router-link :to="'/document/details/' + data.item.id">
                    <font-awesome-icon
                      icon="pencil-alt"
                      class="text-warning"
                      title="View"
                    />
                  </router-link>
                </b-button>
                <b-button variant="link" class="px-1 py-0">
                  <font-awesome-icon
                    icon="download"
                    class="text-primary"
                    @click="downloadDataById(data.item.id, data.item.idCard)"
                    v-if="data.item.files.length > 0"
                  />
                </b-button>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex mt-2">
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.pageSize"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>

              <div class="ml-2">
                <p class="total-record-paging text-nowrap text-center">
                  {{ totalRowMessage }}
                </p>
              </div>
            </div>

            <b-form-select
              v-model="filter.pageSize"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- Modal -->
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="Delete"
      colorBtnConfirm="danger"
      btnCancel="Close"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />

    <b-modal ref="loadingModal" hide-header hide-footer centered>
      <div class="text-center">
        <img
          src="@/assets/images/icons/loading.svg"
          alt="loading"
          class="mb-3 w-25"
        />
        <h1 class="font-weight-bold text-modal">
          In progress. Exporting Data...
        </h1>
      </div>
    </b-modal>

    <b-modal
      id="showPreviewModal"
      ref="showPreviewModal"
      hide-header
      hide-footer
      no-close-on-backdrop
      centered
      body-class="p-4"
      size="md"
    >
      <div class="modal-header border-0 px-0 pt-0">
        <h3 class="font-weight-bold">Image Preview</h3>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('showPreviewModal')"
        >
          ×
        </button>
      </div>
      <div>
        <b-container class="p-0">
          <b-row>
            <b-col>
              <div
                class="preview-box b-contain m-auto border-0"
                v-bind:style="{ 'background-image': 'url(' + img + ')' }"
              ></div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import axios from "axios";
import * as moment from "moment/moment";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import VueCookies from "vue-cookies";

export default {
  name: "user",
  components: {
    InputSelect,
    ModalAlertConfirm,
    ModalAlert,
    ModalAlertError,
  },
  data() {
    return {
      statusList: [],
      modalMessage: "",
      requestDeleteUser: {
        userId: null,
      },
      fields: [
        {
          key: "ids",
          label: "#",
        },
        {
          key: "createdTime",
          label: "Transaction Date",
          class: "w-100px text-nowrap",
        },
        {
          key: "idCard",
          label: "ID Card",
          class: "w-100px text-nowrap",
        },
        {
          key: "customerName",
          label: "Customer Name",
          class: "w-100px text-nowrap",
        },
        {
          key: "lineName",
          label: "Line Name",
          class: "w-100px text-nowrap",
        },
        {
          key: "attachFile",
          label: "Attach File",
          class: "w-100px text-nowrap",
        },
        {
          key: "documentStatusName",
          label: "Status",
          class: "w-100px text-nowrap",
        },
        {
          key: "files",
          label: "Picture",
        },
        {
          key: "id",
          label: "Action",
          class: "w-100px text-nowrap",
        },
      ],
      items: [],
      isBusy: false,
      error: false,
      rows: 0,
      filter: {
        startDate: "",
        endDate: "",
        search: "",
        documentStatus: [],
        page: 1,
        pageSize: 10,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      totalRowMessage: "",
      img: "",
      selectedAll: false,
      selected: [],
      allItems: [],
      totalData: 0,
    };
  },
  created: async function () {
    this.$isLoading = true;
    await this.getData();
    await this.getStatusList();
    // await this.getAllData();
    this.$isLoading = false;
  },
  watch: {
    selected: function () {
      if (this.selected.length == this.allItems.length) {
        this.selectedAll = true;
      } else {
        this.selectedAll = false;
      }
    },
    selectedAll: function () {
      if (this.selected.length != this.allItems.length) {
        if (this.selectedAll) {
          this.selected = [];
          this.allItems.forEach((element, index) => {
            this.selected.push(element.id);
          });
        }
      } else {
        if (!this.selectedAll) {
          this.selected = [];
        }
      }
    },
  },
  methods: {
    showPreview(img) {
      this.img = img;
      this.$refs["showPreviewModal"].show();
    },
    getData: async function () {
      this.isBusy = true;
      let resData = await this.$callApi(
        "put",
        `${this.$baseUrl}/api/paymentDocument/list`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.data;
        this.rows = resData.detail.totalData;
        if (resData.detail.totalData > 0) {
          let numRecordFirst = this.filter.page * this.filter.pageSize;
          numRecordFirst = numRecordFirst - this.filter.pageSize + 1;
          let numRecordLast =
            this.filter.page * this.filter.pageSize > resData.detail.totalData
              ? resData.detail.totalData
              : this.filter.page * this.filter.pageSize;
          this.totalRowMessage =
            "Showing " +
            numRecordFirst +
            " - " +
            numRecordLast +
            " of " +
            resData.detail.totalData +
            " entries";
        }

        this.isBusy = false;
      }
    },
    getAllData: async function () {
      // get ข้อมูลทั้งหมดมาใช้ตอนเช็คติ้ก
      let filterAll = {
        startDate: "2020-06-26",
        endDate: "2020-08-30",
        search: "ใจ",
        documentStatus: [],
        page: 1,
        pageSize: -1,
      };

      let data = await this.$callApi(
        "put",
        `${this.$baseUrl}/api/paymentDocument/list`,
        null,
        this.$headers,
        filterAll
      );

      if (data.result == 1) {
        this.allItems = data.detail.data;
      }
    },
    getStatusList: async function () {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/paymentDocument/status`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.statusList = resData.detail;
      }
    },
    pagination(Page) {
      this.filter.page = Page;
      this.getData();
    },
    hanndleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.pageSize = value;
      this.getData();
    },
    handleSearch(e) {
      if (e.keyCode === 13) {
        this.filter.page = 1;
        this.getData();
      }
    },
    btnSearch() {
      this.filter.page = 1;
      this.getData();
    },
    hanndleStatusList(value) {
      this.filter.page = 1;
      this.filter.statusId = value;
      this.getData();
    },
    openModalDelete(value) {
      this.requestDeleteUser.userId = value.userId;
      this.modalMessage = "คุณต้องการลบ" + value.customerName + "ใช่หรือไม่?";
      this.$refs.ModalAlertConfirm.show();
    },
    onClearFilter() {
      this.filter.page = 1;
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.filter.documentStatus = [];
      this.$refs.filterSidebar.hide(true);
      this.getData();
    },
    btnDelete: async function () {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/customer/delete`,
        null,
        this.$headers,
        this.requestDeleteUser
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        this.filter.page = 1;
        await this.getList();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    getDataByStatus() {
      if (new Date(this.filter.endDate) < new Date(this.filter.startDate)) {
        this.error = true;
        return;
      }

      this.$refs.filterSidebar.hide();
      this.error = false;
      this.getData();
    },
    exportData: async function (type) {
      this.filter.fileType = type;

      this.$refs.loadingModal.show();

      axios({
        url: `${this.$baseUrl}/api/paymentDocument/export`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: this.filter,
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateExcel = moment().format("DDMMYYYYhhmmss");

          this.$refs.loadingModal.hide();

          var typeName = "";
          if (type == 1) typeName = ".xlsx";
          else typeName = ".csv";

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Document-List-` + dateExcel + typeName
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          if (error.response.status === 500) {
          }
        });
    },
    downloadData: async function () {
      let data = {
        Id: this.selected,
      };

      this.$refs.loadingModal.show();

      var config = {
        method: "post",
        url: `${this.$baseUrl}/api/paymentDocument/download`,
        headers: {
          "API-KEY": "SK1ADM1FND",
          Authorization: `Bearer ${VueCookies.get("token")}`,
        },
        data: data,
        responseType: "arraybuffer",
      };

      axios(config)
        .then((response) => {
          this.$refs.loadingModal.hide();
          var dateExcel = moment().format("DDMMYYYYhhmmss");
          const blob = new Blob([response.data], { type: "octet/stream" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Document-List-` + dateExcel + `.zip`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    downloadDataById: async function (id, idcard) {
      this.$refs.loadingModal.show();

      var config = {
        method: "get",
        url: `${this.$baseUrl}/api/paymentDocument/download/` + id,
        headers: {
          "API-KEY": "SK1ADM1FND",
          Authorization: `Bearer ${VueCookies.get("token")}`,
        },
        responseType: "arraybuffer",
      };

      axios(config)
        .then((response) => {
          this.$refs.loadingModal.hide();
          var dateExcel = moment().format("DDMMYYYYhhmmss");
          const blob = new Blob([response.data], { type: "octet/stream" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Document-Data-` + idcard + `-` + dateExcel + `.zip`;
          link.click();
          URL.revokeObjectURL(link.href);
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>      

<style scoped>
.see-all-pic {
  display: table;
  background: gainsboro;
}

.see-all-pic span {
  display: table-cell;
  vertical-align: middle;
}

.doc-status {
  font-size: 16px;
  font-weight: bold;
}
</style>